<template>
	<div class="total drag" v-draw id="CfbSoftMeasure">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_煤量软测量</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				煤量软测量
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context">
			<div class="flex">
				<div class="table">
					<div class="shadow">1#</div>
					<div class="top flex">
						<div class="column1">给煤测量方式</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLXZ1,'按钮','MLXZ1','MCSYCL')">
						    {{infoList.MCSYCL.MLXZ1 ? "软测量" : "实际测量"}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量K值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLK1,'给煤软测量K值','MLK1','MCSYCL')">
							{{infoList.MCSYCL.MLK1}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量C值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLC1,'给煤软测量C值','MLC1','MCSYCL')">
							{{infoList.MCSYCL.MLC1}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量结果</div>
						<div class="column3"
						@click="toCompon(2,'MLRCL1','MCSYCL','MLRCL1VV1','1#给煤软测量结果')"
						@dblclick="Cclick(infoList.MCSYCL.MLRCL1,'MLRCL1','MCSYCL','MLRCL1VV1')">
							{{infoList.MCSYCL.MLRCL1}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤实际测量</div>
						<div class="column3"
						@click="toCompon(2,'FT115D_B','MCSSOURCE','FT115D_BVV1','#1皮带给煤机给煤量')"
						@dblclick="Cclick(infoList.MCSSOURCE.FT115D_B,'FT115D_B','MCSSOURCE','FT115D_BVV1')">
							{{infoList.MCSSOURCE.FT115D_B}}
						</div>
					</div>
				</div>
				<div class="table right">
					<div class="shadow">2#</div>
					<div class="top flex">
						<div class="column1">给煤测量方式</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLXZ2,'按钮','MLXZ2','MCSYCL')">
						    {{infoList.MCSYCL.MLXZ2 ? "软测量" : "实际测量"}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量K值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLK2,'给煤软测量K值','MLK2','MCSYCL')">
							{{infoList.MCSYCL.MLK2}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量C值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLC2,'给煤软测量C值','MLC2','MCSYCL')">
							{{infoList.MCSYCL.MLC2}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量结果</div>
						<div class="column3"
						@click="toCompon(2,'MLRCL2','MCSYCL','MLRCL2VV1','2#给煤软测量结果')"
						@dblclick="Cclick(infoList.MCSYCL.MLRCL2,'MLRCL2','MCSYCL','MLRCL2VV1')">
							{{infoList.MCSYCL.MLRCL2}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤实际测量</div>
						<div class="column3"
						@click="toCompon(2,'FT116D_B','MCSSOURCE','FT116D_BVV1','#2皮带给煤机给煤量')"
						@dblclick="Cclick(infoList.MCSSOURCE.FT116D_B,'FT116D_B','MCSSOURCE','FT116D_BVV1')">
							{{infoList.MCSSOURCE.FT116D_B}}
						</div>
					</div>
				</div>
			</div>
			<div class="bottom flex">
				<div class="table">
					<div class="shadow">3#</div>
					<div class="top flex">
						<div class="column1">给煤测量方式</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLXZ3,'按钮','MLXZ3','MCSYCL')">
						    {{infoList.MCSYCL.MLXZ3 ? "软测量" : "实际测量"}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量K值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLK3,'给煤软测量K值','MLK3','MCSYCL')">
							{{infoList.MCSYCL.MLK3}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量C值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLC3,'给煤软测量C值','MLC3','MCSYCL')">
							{{infoList.MCSYCL.MLC3}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量结果</div>
						<div class="column3"
						@click="toCompon(2,'MLRCL3','MCSYCL','MLRCL3VV1','3#给煤软测量值')"
						@dblclick="Cclick(infoList.MCSYCL.MLRCL3,'MLRCL3','MCSYCL','MLRCL3VV1')">
							{{infoList.MCSYCL.MLRCL3}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤实际测量</div>
						<div class="column3"
						@click="toCompon(2,'FT117D_B','MCSSOURCE','FT117D_BVV1','#3皮带给煤机给煤量')"
						@dblclick="Cclick(infoList.MCSSOURCE.FT117D_B,'FT117D_B','MCSSOURCE','FT117D_BVV1')">
							{{infoList.MCSSOURCE.FT117D_B}}
						</div>
					</div>
					<div></div>
				</div>
				<div class="table right">
					<div class="shadow">4#</div>
					<div class="top flex">
						<div class="column1">给煤测量方式</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLXZ4,'按钮','MLXZ4','MCSYCL')">
						    {{infoList.MCSYCL.MLXZ4 ? "软测量" : "实际测量"}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量K值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLK4,'给煤软测量K值','MLK4','MCSYCL')">
							{{infoList.MCSYCL.MLK4}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量C值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLC4,'给煤软测量C值','MLC4','MCSYCL')">
							{{infoList.MCSYCL.MLC4}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量结果</div>
						<div class="column3"
						@click="toCompon(2,'MLRCL4','MCSYCL','MLRCL4VV1','4#给煤软测量值')"
						@dblclick="Cclick(infoList.MCSYCL.MLRCL4,'MLRCL4','MCSYCL','MLRCL4VV1')">
							{{infoList.MCSYCL.MLRCL4}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤实际测量</div>
						<div class="column3"
						@click="toCompon(2,'FT118D_B','MCSSOURCE','FT118D_BVV1','#4皮带给煤机给煤量')"
						@dblclick="Cclick(infoList.MCSSOURCE.FT118D_B,'FT118D_B','MCSSOURCE','FT118D_BVV1')">
							{{infoList.MCSSOURCE.FT118D_B}}
						</div>
					</div>
				</div>
			</div>
			<div class="bottom flex">
				<div class="table">
					<div class="shadow">5#</div>
					<div class="top flex">
						<div class="column1">给煤测量方式</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLXZ5,'按钮','MLXZ5','MCSYCL')">
						    {{infoList.MCSYCL.MLXZ5 ? "软测量" : "实际测量"}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量K值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLK5,'给煤软测量K值','MLK5','MCSYCL')">
							{{infoList.MCSYCL.MLK5}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量C值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLC5,'给煤软测量C值','MLC5','MCSYCL')">
							{{infoList.MCSYCL.MLC5}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量结果</div>
						<div class="column3"
						@click="toCompon(2,'MLRCL5','MCSYCL','MLRCL5VV1','5#给煤软测量值')"
						@dblclick="Cclick(infoList.MCSYCL.MLRCL5,'MLRCL5','MCSYCL','MLRCL5VV1')">
							{{infoList.MCSYCL.MLRCL5}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤实际测量</div>
						<div class="column3"
						@click="toCompon(2,'FT119D_B','MCSSOURCE','FT119D_BVV1','#5皮带给煤机给煤量')"
						@dblclick="Cclick(infoList.MCSSOURCE.FT119D_B,'FT119D_B','MCSSOURCE','FT119D_BVV1')">
							{{infoList.MCSSOURCE.FT119D_B}}
						</div>
					</div>
					<div></div>
				</div>
				<div class="table right">
					<div class="shadow">6#</div>
					<div class="top flex">
						<div class="column1">给煤测量方式</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLXZ6,'按钮','MLXZ6','MCSYCL')">
						    {{infoList.MCSYCL.MLXZ6 ? "软测量" : "实际测量"}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量K值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLK6,'给煤软测量K值','MLK6','MCSYCL')">
							{{infoList.MCSYCL.MLK6}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量C值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSYCL.MLC6,'给煤软测量C值','MLC6','MCSYCL')">
							{{infoList.MCSYCL.MLC6}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤软测量结果</div>
						<div class="column3"
						@click="toCompon(2,'MLRCL6','MCSYCL','MLRCL6VV1','6#给煤软测量值')"
						@dblclick="Cclick(infoList.MCSYCL.MLRCL6,'MLRCL6','MCSYCL','MLRCL6VV1')">
							{{infoList.MCSYCL.MLRCL6}}
						</div>
					</div>
					<div class="top flex">
						<div class="column1">给煤实际测量</div>
						<div class="column3"
						@click="toCompon(2,'FT120D_B','MCSSOURCE','FT120D_BVV1','#6皮带给煤机给煤量')"
						@dblclick="Cclick(infoList.MCSSOURCE.FT120D_B,'FT120D_B','MCSSOURCE','FT120D_BVV1')">
							{{infoList.MCSSOURCE.FT120D_B}}
						</div>
					</div>
				</div>
			</div>
		</div>
	<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus = 'isHshow=false' :historyname = 'historyname' :node='node' :Lkname='Lkname' :chName="chName" :infoList = 'infoList'></Historical>
		<Firstcontrol 
		:infoList = 'infoList' 
		:isIndex = 'isIndex'
		:historyname = 'Firstcontrolname'
		:titname = 'Firstcontroltitname'
		:node = 'Firstcontrolnode'
		v-if="isFshow"
		@sendStatus = 'isFshow=false'></Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus = 'isRshow=false' :titname = 'Rsftitname' :infoList = 'infoList' :historyname = 'Rsfname' :node = 'Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow"  :infoList = 'infoList' @sendStatus = 'isshowfase'></ParameterYh>
		<Manual
		  :key="isIndex"
		  v-if="isMshow"
		  @sendStatus="isMshow = false"
		  :historyname="Manualname"
		  :node="Manualnode"
		  :Lkname="ManualAname"
		  :titname="Manualtitname"
		  :infoList="infoList">
		</Manual>
		</div>
	</template>
	
	<script>	
		import inputVal from "@/components/inputVal.vue"; //输入框组件
		import Historical from "@/components/Historical.vue"  //历史趋势
		import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
		import Rsf from "@/components/RSF.vue"; //阮伺服组件
		import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
		import Manual from "@/components/Manual.vue"; //手操器组件
		export default {
		    name: "CfbParameter3",
		    props:{
	        infoList:{
	            type:Object,
	            default: () =>{
	              return {}// 默认值
	            } 
	        }
	    },
		 components:{inputVal, Historical,Firstcontrol,Rsf,ParameterYh,Manual},
		    data: () => {
		  return {
			  chName: '',
		      Manualname: "",
		      Manualnode: "",
		      ManualAname: "",
		      Manualtitname: "",
		      isComShow:false,
		      isHshow:false,
		      isMshow:false,
		      isRshow:false,
		      historyname:'',
		      node:'',
		      Lkname:'',
		      isIndex:'',
		      Firstcontrolname:'',
		      Firstcontroltitname:'',
		      Firstcontrolnode:'',
		      isFshow:false,
		      Rsfname:'',
		      Rsfnode:'',
		      Rsftitname:'',
		      projectData:'',
		      grouptime:null,
		      spotArr:[],
		      authInfo:[],
		      UserInfo:[],
		  }
		        
		 },
		   watch: {
	    infoList: {
	      handler(n, o) {
	        this.infoList = n;
			console.log(this.infoList)
	      },
	      deep: true, // 深度监听父组件传过来对象变化
	    },
	  },
		  created(){
		    this.projectData = JSON.parse(localStorage.getItem("deviceType"))
		    this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		    this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			
		  },
		  methods:{
		      // 打开先控多窗口
		  toDetail(key,name,nodename,Aname,titname){
		   if(!this.authInfo[1]){
		    let str = this.authInfo[0]
		    // 判断是否为调试还是查看权限
		    if (str.charAt(str.length-1) == "r") {
		     this.$message.error('暂无调试权限');
		    }
		   }else{
		    let pathname=""
		    switch(key){
		     case 1:
		     pathname = 'firstcontol'
		     break
		     case 2:
		     pathname = 'Cfbkfirstcontol'
		     break
		     case 3:
		     pathname = 'CfbRfirstcontol'
		     break
		    }
		    const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
		    window.open(newurl.href,'_blank')
		   }
		  },
		         // 双击事件
		    Cclick(num,mark,node,name){
		      // 组装数据
		      clearTimeout(this.grouptime);
		      let spojobj = {
		        num:num,
		        mark:mark,
		        node:node,
		        name:name
		      }
		      this.spotArr.push(spojobj)
		      // 数组去重
		      let deWeightThree = () => {
		          let map = new Map();
		          for (let item of this.spotArr) {
		              if (!map.has(item.name)) {
		                 this.$message.closeAll()
		                  map.set(item.name, item);
		                 this.$message.success('已添加');
		              }else{
		                this.$message.closeAll()
		                this.$message.error('已添加到变量池里，切勿重复添加');
		              }
		          }
		       
		          return [...map.values()];
		      }
		      this.spotArr = deWeightThree();
		      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
		    },
		       isshowfase(){
		       this.isComShow = false
		     },
		    closeCompon(){
		
		      this.$emit('sendStatus', 'Param041',false)
		    },
		    isClose(val,val1){
		      switch(val){
		        case 'Historical':
		          return this.isHshow = val1
		      }
		    },
		    toIpt(data,name,historyname,node){
		
		      this.$refs.inputVal.open(data,name,historyname,node)
		    },
		    toCompon(key,name,name2,name3,name4,titname){
		   this.isIndex = key
		   this.historyname = name
		   this.node = name2
		   this.Lkname = name3
		      clearTimeout(this.grouptime);
		      this.grouptime=setTimeout(() =>{
		          switch(key){
		      case 0:
				  console.log(1)
		          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
		        case 1 :
		          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
		        case 2:
		          return  this.isHshow = true, this.chName = name4
		        case 4:
		    return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
		   }
		      },300);    
		  },
		    } 
		}
	</script>
	

<style lang="scss" scoped>
	.total{
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;
		.title-hang {
		  width: 53vw;
		  height: 5vh;
		  font-family: MicrosoftYaHei;
		  font-size: 2vh;
		  font-weight: normal;
		  font-stretch: normal;
		  line-height: 2vh;
		  letter-spacing: 0vh;
		  margin-left: 15vw;
		}
		.title{
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top:1vh;
			text-align: center;
			margin-left: 1.5vw;
		} 
		.icon{
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}
		.main{
			width: 53vw;
			height: 2vh;
			margin-top: 2vh;
			.main_title{
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}
		.context{
			width: 47.55vw;
			height: 24.54vh;
			margin-top: 1.5vh;
			margin-left: 3.13vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;
			.shadow{
				width: 22.3vw;
				height: 3.24vh;
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 1px;
				border: solid 1px #236f8d;
				font-size: 1.85vh;
				line-height: 3vh;
				color: #d5fffe;
				text-align: center;
			}
			.table{
				width: 22.5vw;
				height: 16.39vh;
				border:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
			}
			.right{
				margin-left: 1.98vw;
			}
			.top{
				font-size: 1.48vh;
				line-height: 2.42vh;
				border-top:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.column1{
					width: 7vw;
					color: #00e4ff;
					margin-left: 2.14vw;
				}
				.column2{
					width: 5vw;
					color: #00ffb4;
					margin-left: 6vw;
					text-align: center;
				}
				.column3{
					width: 5vw;
					color: #2fc3e3;
					margin-left: 6vw;
					text-align: center;
				}
			}
			.bottom{
				margin-top: 1.85vh;
			}
		}
	}
</style>
